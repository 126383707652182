body {
    font-family: "Figtree", ui-sans-serif, system-ui, sans-serif !important;
    background-color: #000 !important;
    opacity: 0.6;
}

.title {
    color: #fdfdfd;
}

.dropdown {
    background: #444 !important;
}

.hidden {
    visibility: hidden;
}